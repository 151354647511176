import i18n from "@/common/plugins/vue-i18n";

export const headers = [
  {
    label: i18n.t("LOG.CREATED_AT"),
    key: "createdAt",
    sortable: true
  },
  {
    label: i18n.t("LOG.USER"),
    key: "email",
    sortable: false
  },
  {
    label: i18n.t("LOG.FIELDS_CHANGED"),
    key: "fieldsChanged",
    sortable: true
  },
  {
    label: i18n.t("LOG.ACTION"),
    key: "action",
    sortable: true
  },
  {
    label: i18n.t("LOG.LEVEL"),
    key: "level",
    sortable: true
  }
  // ,
  // {
  //   label: i18n.t("LOG.RESOURCE_ID"),
  //   key: "resourceId",
  //   sortable: true
  // }
];
