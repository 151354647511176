<template>
  <div>
    <h5 class="card-title mb-0 font-weight-bold">
      {{ $tc("CAMPAIGN_AUDIENCE.LISTENS_PER_DAY") }}
    </h5>

    <div>
      <div
        v-if="!hasDataHeatMap"
        class="height-heatmap d-flex flex-column justify-content-center text-center mt-3"
      >
        <span class="font-weight-bold">{{
          $tc("DETAILS_V2.CAMPAIGN_NOT_READY")
        }}</span>
        <span class="font-weight-bold">{{ $tc("DETAILS_V2.NO_STATS") }}</span>
      </div>

      <apexchart
        v-if="!isLoadingHeatmap && hasDataHeatMap"
        type="heatmap"
        height="150"
        width="100%"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>

    <div
      v-if="isLoadingHeatmap"
      class="d-flex align-items-center justify-content-center container-spinner"
    >
      <b-spinner class="mt-2" variant="primary" label="Spinning"></b-spinner>
    </div>

    <div class="d-flex flex-column mt-8">
      <h5 class="card-title mb-0 font-weight-bold">
        {{ $tc("CAMPAIGN_AUDIENCE.GEOLOCALISATION") }}
      </h5>

      <div class="d-none d-sm-flex mt-3">
        <div v-if="isLoadingMapDomains" class="container-loading-map">
          <b-spinner
            class="mt-2"
            variant="primary"
            label="Spinning"
          ></b-spinner>
          <span class="ml-2 font-weight-bold text-primary">{{
            $tc("DETAILS_V2.LOADING")
          }}</span>
        </div>

        <div
          v-show="!isLoadingMapDomains"
          class="w-100 d-flex flex-column mt-3"
        >
          <div class="w-100 d-flex flex-column align-items-center">
            <div
              id="listens-map"
              class="container-map"
              :style="`width: 100%;`"
            ></div>
          </div>

          <div class="position-absolute" style="margin-top: 40px">
            <div class="d-flex flex-column card p-1 ml-2">
              <div class="card-body">
                <DatatableDetailsV2
                  id="geoloc-table"
                  :header="getHeaderGeoloc"
                  :items="itemGeolocData"
                  :loading="isLoadingGeoloc"
                  :perPage="perPageGeoloc"
                  :totalRows="totalsRowsGeloc"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-flex d-sm-none flex-column align-items-center mt-3">
        <div class="d-flex flex-column w-75">
          <DatatableDetailsV2
            id="geoloc-mobile-table"
            :header="getHeaderGeoloc"
            :items="itemGeolocData"
            :loading="isLoadingGeoloc"
            :perPage="perPageGeolocMobile"
            :totalRows="totalsRowsGeloc"
          />
        </div>
      </div>
    </div>

    <div class="d-flex flex-column mt-8">
      <h5 class="card-title mb-0 font-weight-bold">
        {{ $tc("CAMPAIGN_AUDIENCE.DOMAINS") }}
      </h5>

      <div class="w-100 d-flex flex-column align-items-center mt-3">
        <div class="d-flex flex-column w-75">
          <DatatableDetailsV2
            id="domains-table"
            :header="getHeaderDomains"
            :items="itemsStatsDomains"
            :loading="isLoadingDomains"
            :perPage="perPageDomains"
            :totalRows="totalsRowsDomains"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSponsoriginGeolocStatsV2,
  getStatstListensPerDomains,
  getSponsoringHeatmapV2
} from "@/api/stats/campaigns/getters";
import {
  headerDomainsStats,
  headerGeoloc,
  optionsWorldMap
} from "@/components/sponsoring/headerConfig";
import world from "@/assets/media/world";
import * as echarts from "echarts";
import DatatableDetailsV2 from "@/components/sponsoring/DatatableDetailsV2";

export default {
  name: "CampaignAudience",

  components: {
    DatatableDetailsV2
  },

  props: {
    campaignOrder: {
      type: Object,
      required: true
    },
    campaignFilters: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      isLoadingHeatmap: false,
      isLoadingGeoloc: false,
      itemGeolocData: [],
      currentPageGeoloc: 1,
      perPageGeoloc: 3,
      perPageGeolocMobile: 10,
      isLoadingMap: false,
      isLoadingDomains: false,
      itemsStatsDomains: [],
      currentPageDomains: 1,
      perPageDomains: 10,
      listensMap: {},
      series: [
        {
          name: "Listens",
          data: []
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "heatmap"
        },
        dataLabels: {
          enabled: true
        },
        colors: ["#4740C9"],
        xaxis: {
          type: "Days",
          categories: [],
          crosshairs: {
            show: true
          }
        }
      }
    };
  },

  computed: {
    totalsRowsGeloc() {
      return this.itemGeolocData.length;
    },

    totalsRowsDomains() {
      return this.itemsStatsDomains.length;
    },

    hasItemGeolocData() {
      return this.itemGeolocData.length > 9;
    },

    hasItemsStatsDomains() {
      return this.itemsStatsDomains.length > 0;
    },

    hasDataHeatMap() {
      return this.series[0].data.length > 0;
    },

    getHeaderGeoloc() {
      return headerGeoloc;
    },

    getHeaderDomains() {
      return headerDomainsStats;
    },

    isLoadingMapDomains() {
      return this.isLoadingDomains || this.isLoadingMap;
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.listensMap.resize(), false);
    optionsWorldMap.series[0].data = [];
  },

  methods: {
    async loadHeatMap() {
      this.isLoadingHeatmap = true;

      this.series[0].data = [];

      try {
        const response = await getSponsoringHeatmapV2(
          this.campaignOrder.id,
          "advertiser",
          this.campaignFilters,
          this.axios
        );

        const heatmapData = Object.entries(response);

        let heatmapCategories = [];
        let series = [];

        heatmapData.forEach(element => {
          heatmapCategories.push(element[0]);
          series.push(element[1]);
        });

        const sumSeries = series.reduce((a, b) => a + b);

        if (sumSeries > 0) {
          this.chartOptions.xaxis.categories = heatmapCategories;
          this.series[0].data = series;
        }
      } catch (error) {
        console.error("---ERROR-HEATMAP---");
        console.error(error);
      }

      this.isLoadingHeatmap = false;
    },

    async loadGeoloc() {
      this.isLoadingGeoloc = true;

      try {
        if (this.campaignFilters.start_date > 0) {
          const response = await getSponsoriginGeolocStatsV2(
            this.campaignOrder.id,
            this.campaignFilters,
            this.axios
          );

          this.loadCreateMap(response);
          this.itemGeolocData = response;
        }
      } catch (error) {
        console.error("---ERROR-GEOLOC---");
        console.error(error);
      }

      this.isLoadingGeoloc = false;
    },

    loadCreateMap(countries) {
      this.isLoadingMap = true;

      let data = [];

      countries.forEach(element => {
        data.push({
          name: element.country_name,
          value: element.listen
        });
      });

      const chartDom = document.getElementById("listens-map");
      this.listensMap = echarts.init(chartDom);
      echarts.registerMap("world", world);
      const option = optionsWorldMap;

      option.series[0].data = data;

      this.listensMap.setOption(option);

      option && this.listensMap.setOption(option);

      //It's needed for resizing the map
      window.addEventListener("resize", () => this.listensMap.resize());

      this.isLoadingMap = false;
    },

    async loadDomains() {
      this.isLoadingDomains = true;

      try {
        const response = await getStatstListensPerDomains(
          this.campaignOrder.id,
          this.campaignFilters,
          this.axios
        );
        this.itemsStatsDomains = response;
      } catch (error) {
        console.error("---ERROR-MAP---");
        console.error(error);
      }

      this.isLoadingDomains = false;
    }
  },

  watch: {
    campaignFilters() {
      this.loadHeatMap();
      this.loadGeoloc();
      this.loadDomains();
    },

    isShow() {
      window.dispatchEvent(new Event("resize"));
    }
  }
};
</script>

<style scoped>
.height-heatmap {
  height: 150px;
  background: #f7f8fa;
}

.container-loading-map {
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-map {
  width: 100%;
  height: 350px;
}
</style>
