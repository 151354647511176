export const chartArea = {
  type: "area",
  data: {
    datasets: [
      {
        data: [],
        label: "Impressions",
        yAxisID: "impressions",
        backgroundColor: "#4640c9",
        borderColor: "#4640c9",
        fill: {
          target: "origin",
          above: "rgb(255, 0, 0)",
          below: "rgb(0, 0, 255)"
        }
      },
      {
        data: [],
        label: "Listens",
        yAxisID: "listens",
        backgroundColor: "#369aff",
        borderColor: "#369aff",
        fill: {
          target: "origin",
          above: "rgb(255, 0, 0)",
          below: "rgb(0, 0, 255)"
        }
      }
    ],
    labels: []
  },
  loading: false,
  stacked: true
};

export const chartAreaOptions = {
  scales: {
    yAxes: [
      {
        id: "impressions",
        type: "linear",
        position: "left",
        stacked: false,
        scaleLabel: {
          display: true,
          labelString: "impressions"
        },
        ticks: {
          beginAtZero: true,
          padding: 25,
          precision: 0
        },
        gridLines: {
          display: true,
          drawBorder: false,
          borderDash: [4, 8],
          color: "#B5B5C3"
        }
      },
      {
        id: "listens",
        type: "linear",
        position: "right",
        scaleLabel: {
          display: true,
          labelString: "Listens"
        },
        ticks: {
          beginAtZero: true,
          padding: 25,
          precision: 0
        },
        gridLines: {
          display: false
        }
      }
    ]
  },
  legend: {
    display: false,
    labels: {
      usePointStyle: true,
      boxWidth: 14
    },
    align: "end"
  }
};

export const chartDoughnut = {
  type: "doughnut",
  data: {
    datasets: [
      {
        label: "Listens",
        data: [],
        backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16", "#003AAf"]
      }
    ],
    labels: []
  },
  loading: false,
  stacked: true
};

export const chartDoughnutOptions = {
  legend: {
    display: true,
    align: "center"
  }
};
