<template>
  <div>
    <b-table
      :id="id"
      striped
      hover
      responsive
      :fields="header"
      :items="items"
      :busy="loading"
      :per-page="perPage"
      :current-page="currentPage"
      show-empty
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $tc("DETAILS_V2.LOADING") }}</strong>
        </div>
      </template>

      <template #empty>
        <div class="d-flex justify-content-center">
          <span class="font-weight-bold">{{ $tc("DETAILS_V2.NO_STATS") }}</span>
        </div>
      </template>
    </b-table>

    <div class="w-100 d-flex justify-content-center">
      <b-pagination
        v-if="hasItems"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        :aria-controls="id"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: false
    },
    header: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    totalRows: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      currentPage: 1
    };
  },

  computed: {
    hasItems() {
      return this.items.length > this.perPage;
    }
  }
};
</script>
