<template>
  <b-table
    :items="items"
    :fields="fields"
    responsive
    striped
    hover
    :busy="isLoading"
    show-empty
  >
    <template #thead-top>
      <b-tr>
        <b-th colspan="1"></b-th>
        <b-th class="border text-center text-muted" colspan="2">Listens</b-th>
        <b-th class="border text-center text-muted" colspan="2"
          >Impressions</b-th
        >
        <b-th class="border text-center text-muted" colspan="2">Time</b-th>
      </b-tr>
    </template>

    <template #custom-foot>
      <b-tr>
        <b-td class="text-right"><b>Total</b></b-td>
        <b-td
          ><b>{{ totals["paid:plays"] }}</b></b-td
        >
        <b-td
          ><b>{{ Number(totals["play:rate"]).toFixed(2) }} %</b></b-td
        >
        <b-td
          ><b>{{ totals["paid:impressions"] }}</b></b-td
        >
        <b-td
          ><b>{{ Number(totals["conversion:rate"]).toFixed(2) }} %</b></b-td
        >
        <b-td
          ><b>{{ renderSeconds(totals["paid:timelistenings:avg"]) }}</b></b-td
        >
        <b-td
          ><b>{{ renderSeconds(totals["paid:timelistenings"]) }}</b></b-td
        >
      </b-tr>
    </template>

    <template #empty>
      <div class="d-flex justify-content-center">
        <span class="font-weight-bold">{{ $tc("DETAILS_V2.NO_STATS") }}</span>
      </div>
    </template>

    <template #table-busy>
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle mr-1"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>

    <template #cell(playRate)="data">
      {{ data.item.stats["play:rate"] }} %
    </template>

    <template #cell(conversionRate)="data">
      {{ data.item.stats["conversion:rate"] }} %
    </template>

    <template #cell(averageTimeListenings)="data">
      {{ renderSeconds(data.item.stats["paid:timelistenings:avg"]) }}
    </template>

    <template #cell(totalTimeListening)="data">
      {{ renderSeconds(data.item.stats["paid:timelistenings"]) }}
    </template>

    <template #cell(episode)="data">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="font-weight-bold">{{
            truncateTitle(data.item.episode.title)
          }}</span>
        </template>
        <span>{{ data.item.episode.title }}</span>
      </v-tooltip>
    </template>
  </b-table>
</template>

<script>
import { renderSeconds } from "@/common/functions";

export default {
  props: {
    items: {
      type: Array,
      required: false
    },
    fields: {
      type: Array,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    truncateTitle(title) {
      const MAX_CHARACTERS = 30;

      if (title.length > MAX_CHARACTERS) {
        title = `${title.substring(0, MAX_CHARACTERS)}...`;
      }

      return title;
    },

    renderSeconds(time) {
      return renderSeconds(time);
    }
  },

  computed: {
    totals() {
      const result = [];
      this.items.forEach(element => {
        const stats = element.stats;

        for (const key in stats) {
          if (result[key] === undefined) {
            result[key] = 0;
          }
          result[key] += stats[key];
        }
      });

      result["conversion:rate"] =
        (result["paid:plays"] / result["paid:impressions"]) * 100;

      result["paid:timelistenings:avg"] =
        result["paid:timelistenings"] / result["paid:plays"];

      return result;
    }
  }
};
</script>
