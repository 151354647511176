<template>
  <div class="col m-0 px-0 pt-1">
    <div class="d-flex flex-row border rounded ml-1 h-100">
      <div
        class="d-flex flex-column justify-content-center align-items-center rounded bg-secondary py-3 container-title-card"
      >
        <h6 class="text-center">{{ title }}</h6>
        <i class="menu-icon text-muted h3 mr-4" :class="icon"></i>
      </div>

      <div class="d-flex flex-column py-2 px-2">
        <div class="d-flex align-items-center justify-content-center">
          <h2 class="font-weight-bold text-primary ml-0 h2-total">
            {{ total }}
          </h2>
        </div>

        <div class="d-flex flex-column align-items-center px-3">
          <span class="label-avg-day">{{ label }}</span>
          <h4 class="font-weight-bold text-primary">
            {{ averageDay % 1 > 0 ? averageDay.toFixed(2) : averageDay }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CampaignCardAudience",

  props: {
    title: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    total: {
      type: [Number, String],
      required: false,
      default: 0
    },
    averageDay: {
      type: [Number, String],
      required: false,
      default: 0
    }
  }
};
</script>

<style scoped>
.container-title-card {
  width: 110px;
}

.h2-total {
  font-size: 1.6rem !important;
}

.label-avg-day {
  font-weight: bold;
  font-size: 10px !important;
}
</style>
