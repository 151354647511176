<template>
  <div>
    <div class="d-flex flex-row">
      <div class="d-flex flex-column w-100">
        <div class="d-flex flex-row justify-content-between">
          <div class="d-flex flex-column flex-sm-row">
            <h1 class="d-none d-sm-flex">
              {{ campaignOrder ? campaignOrder.name : "-" }}
            </h1>

            <h4 class="d-sm-none">
              {{ campaignOrder ? campaignOrder.name : "-" }}
            </h4>

            <span
              v-if="campaignOrder.status"
              class="label label-lg label-inline text-capitalize ml-2"
              :class="campaignOrder.status.class"
              >{{ campaignOrder.status.name }}</span
            >
          </div>
        </div>

        <div
          class="d-flex flex-column flex-sm-row justify-content-between align-items-center mb-2 mt-2 mt-sm-0"
        >
          <div>
            <div class="d-flex flex-row align-items-center mt-2 mr-5">
              <span class="font-weight-bold"
                >{{ $tc("CAMPAIGN_HEADER.ADVERTISER") }}:</span
              >
              <h6 class="ml-1 mb-0 text-primary font-weight-bold">
                {{
                  campaignOrder.advertiser ? campaignOrder.advertiser.name : "-"
                }}
              </h6>
            </div>

            <div class="d-flex flex-row align-items-center">
              <span class="font-weight-bold"
                >{{ $tc("CAMPAIGN_HEADER.AGENCY") }}:</span
              >
              <h6 class="ml-1 mb-0 text-primary font-weight-bold">
                {{ campaignOrder.agency ? campaignOrder.agency.name : "-" }}
              </h6>
            </div>

            <div class="d-flex flex-row align-items-center">
              <span class="font-weight-bold">{{
                $tc("CAMPAIGN_HEADER.PROGRAM_NAME")
              }}</span>
              <h6 class="ml-1 mb-0 text-primary font-weight-bold">
                {{ program ? program.name : "-" }}
              </h6>
            </div>
          </div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <img
                v-bind="attrs"
                v-on="on"
                :src="cover"
                width="120"
                class="rounded"
                alt="Podcast thumbnails"
              />
            </template>
            <span>{{ $tc("CAMPAIGN_HEADER.TOOLTIP_THUMBNAIL") }}</span>
          </v-tooltip>
        </div>
      </div>
    </div>

    <div class="kt-portlet kt-portlet__separator">
      <div class="kt-portlet__separator"></div>
    </div>

    <div class="d-flex flex-row flex-wrap">
      <div class="d-flex flex-column w-50">
        <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
          <i
            class="menu-icon flaticon2-calendar-2 text-muted display-1 mr-4"
          ></i>
          <div class="d-flex flex-column">
            <span class="bb-font-bold font-size-sm">{{
              $tc("CAMPAIGN_HEADER.PERIOD")
            }}</span>
            <span class="bb-font-bold font-size-sm text-primary">
              {{ getPeriod }}
            </span>
          </div>
        </div>

        <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
          <i class="menu-icon flaticon2-tag text-muted display-1 mr-4"></i>
          <div class="d-flex flex-column">
            <span class="bb-font-bold font-size-sm">{{
              $tc("CAMPAIGN_HEADER.TARGETING")
            }}</span>

            <div class="d-flex flex-row flex-wrap align-items-end mt-1">
              <template v-for="(item, index) in categories">
                <span
                  v-if="index + 1 <= maxTags"
                  :key="`categorie_${index}`"
                  class="text-primary font-weight-bold border rounded border-primary py-1 px-1 mr-2 mt-1"
                  >{{ item.name }}</span
                >
              </template>

              <v-tooltip v-if="isSpliceCategories" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="text-primary font-weight-bold"
                    >...</span
                  >
                </template>

                <div class="d-flex flex-column">
                  <span
                    v-for="(item, index) in categories.slice(maxTags + 1)"
                    :key="`tooltip_tag_${index}`"
                    >{{ item.name }}</span
                  >
                </div>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column w-50">
        <div class="d-flex align-items-center w-100 mr-5 mb-2">
          <i class="menu-icon flaticon-price-tag text-muted display-1 mr-4"></i>
          <div class="d-flex flex-column w-100">
            <span class="bb-font-bold font-size-sm">{{
              $tc("CAMPAIGN_HEADER.BUDGET")
            }}</span>

            <b-progress :max="100" show-value>
              <b-progress-bar :value="getPercentSpentBudget">
                <span
                  ><strong>{{ getPercentSpentBudget }} %</strong></span
                >
              </b-progress-bar>
            </b-progress>

            <div class="d-flex flex-row justify-content-between">
              <span
                >{{ $tc("EDIT_V2.BUDGET") }}
                <span class="font-weight-bold font-size-sm text-primary"
                  >{{ getCampaignOrderBudget }} €</span
                ></span
              >

              <span
                >{{ $tc("EDIT_V2.BUDGET_SPENT") }}
                <span class="font-weight-bold font-size-sm text-primary"
                  >{{ getBudgetSpentFormat }} €</span
                ></span
              >

              <span
                >{{ $tc("EDIT_V2.BUDGET_LEFT")
                }}<span class="font-weight-bold font-size-sm text-primary"
                  >{{ getLeftBudget }} €</span
                ></span
              >
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
          <i class="menu-icon flaticon2-chat-1 text-muted display-1 mr-4"></i>
          <div class="d-flex flex-column">
            <span class="bb-font-bold font-size-sm">{{
              $tc("CAMPAIGN_HEADER.LANGUAGES")
            }}</span>

            <div class="d-flex flex-row flex-wrap">
              <template v-for="(item, index) in languages">
                <span
                  v-if="index + 1 <= maxTags"
                  :key="`language_${index}`"
                  class="text-primary font-weight-bold mr-1"
                  >{{ item.name }},</span
                >
              </template>

              <v-tooltip v-if="isSpliceCategories" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="text-primary font-weight-bold"
                    >...</span
                  >
                </template>

                <div class="d-flex flex-column">
                  <span
                    v-for="(item, index) in languages.slice(maxTags + 1)"
                    :key="`tooltip_tag_${index}`"
                    >{{ item.name }}</span
                  >
                </div>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { renderSeconds } from "@/common/functions";

export default {
  name: "",

  props: {
    campaignOrder: {
      type: Object,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    languages: {
      type: Array,
      required: false
    },
    budgetSpent: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data() {
    return {
      maxTags: 5
    };
  },

  computed: {
    company() {
      return this.campaignOrder.company;
    },

    hasCategories() {
      return this.categories.length > 0;
    },

    program() {
      return this.campaignOrder.program;
    },

    cover() {
      return (
        this.program?.thumbnails[128] ||
        "/assets/media/logos/logo-voxeus-white.png"
      );
    },

    getPercentSpentBudget() {
      const numberFormat =
        parseFloat(
          (this.budgetSpent * 100) / this.campaignOrder.budget
        ).toFixed(2) || "0";
      return new Intl.NumberFormat(this.language).format(numberFormat);
    },

    getAvgTimeListens() {
      let avgTimeListens = 0;

      if (this.avgTimeListens) {
        avgTimeListens = renderSeconds(this.avgTimeListens);
      }
      return avgTimeListens;
    },

    getVariantBudget() {
      let variant = "success";

      if (this.budgetSpent > this.campaignOrder.budget) {
        variant = "danger";
      }

      return variant;
    },

    getPeriod() {
      const minDate = this.campaignOrder.minDate;
      const maxDate = this.campaignOrder.maxDate;
      let period = "";

      if (minDate && maxDate) {
        period = `${this.$d(new Date(minDate), "short")} - ${this.$d(
          new Date(maxDate),
          "short"
        )}`;
      }

      return period;
    },

    getCampaignOrderBudget() {
      const budget = this.campaignOrder?.budget || 0;
      return new Intl.NumberFormat(this.getLanguage).format(budget);
    },

    getBudgetSpentFormat() {
      const budgetSpent = this.budgetSpent || 0;
      return new Intl.NumberFormat(this.getLanguage).format(budgetSpent);
    },

    getLeftBudget() {
      const budget = this.campaignOrder?.budget || 0;
      const budgetSpent = this.budgetSpent || 0;
      return new Intl.NumberFormat(this.getLanguage).format(
        budget - budgetSpent
      );
    },

    getLanguage() {
      return navigator.language;
    },

    isSpliceCategories() {
      return this.categories.length > this.maxTags;
    },

    isSpliceLanguages() {
      return this.languages.length > this.maxTags;
    }
  }
};
</script>

<style scoped>
.font-size-sm {
  font-size: 0.925rem;
}

.bb-font-bold {
  font-weight: 600 !important;
}
</style>
