import { get as getV2, post as postV2 } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";

export const getSponsoringHeatmapV2 = (campaignId, profile, filters, axios) =>
  postV2(
    {
      path: ENTITY_NAME_IN_URI_V2.HEATMAP.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI_V2.HEATMAP.name
        },
        {
          name: profile,
          value: campaignId
        }
      ]
    },
    {},
    filters,
    axios
  );

export const getSponsoriginGeolocStatsV2 = (campaignId, filters, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.GEOLOCALISATION.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI_V2.GEOLOCALISATION.name,
          value: campaignId
        }
      ]
    },
    filters,
    axios
  );

export const getStatstListensPerDomains = (campaignId, filters, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGN_ORDERS_DOMAINS.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI_V2.CAMPAIGN_ORDERS_DOMAINS.name,
          value: ENTITY_NAME_IN_URI_V2.CAMPAIGN_ORDERS_DOMAINS.value
        },
        {
          name: campaignId
        }
      ]
    },
    filters,
    axios
  );

export const getStatsImpressions = (campaignId, filters, axios) => {
  return getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.GRAPH_CAMPAIGN_DETAILS.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI_V2.GRAPH_CAMPAIGN_DETAILS.name,
          value: ENTITY_NAME_IN_URI_V2.GRAPH_CAMPAIGN_DETAILS.value
        },
        {
          name: campaignId
        }
      ]
    },
    filters,
    axios
  );
};

export const getStatsCompletionRate = (campaignId, filters, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.COMPLETION_RATE_QUARTILE.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI_V2.COMPLETION_RATE_QUARTILE.name,
          value: campaignId
        }
      ]
    },
    filters,
    axios
  );

export const getPerformances = (campaignId, filters, axios) => {
  return postV2(
    {
      path: ENTITY_NAME_IN_URI_V2.GRAPH_CAMPAIGN_PERFORMANCES.path,
      ressources: [
        {
          name: campaignId,
          value: ENTITY_NAME_IN_URI_V2.GRAPH_CAMPAIGN_PERFORMANCES.value
        }
      ]
    },
    {},
    filters,
    axios
  );
};

export const getStatsExport = (campaignId, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS_STATS_EXPORT.path,
      ressources: [
        {
          name: campaignId
        },
        {
          name: ENTITY_NAME_IN_URI_V2.CAMPAIGNS_STATS_EXPORT.name,
          value: ENTITY_NAME_IN_URI_V2.CAMPAIGNS_STATS_EXPORT.value
        }
      ]
    },
    {},
    axios
  );
