import { render, staticRenderFns } from "./CampaignCardAudience.vue?vue&type=template&id=003e3dd2&scoped=true&"
import script from "./CampaignCardAudience.vue?vue&type=script&lang=js&"
export * from "./CampaignCardAudience.vue?vue&type=script&lang=js&"
import style0 from "./CampaignCardAudience.vue?vue&type=style&index=0&id=003e3dd2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "003e3dd2",
  null
  
)

export default component.exports