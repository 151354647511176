<template>
  <div>
    <div
      class="d-flex flex-row flex-wrap justify-content-sm-between justify-content-center"
    >
      <div
        v-if="isLoadingCardAudience"
        class="d-flex flex-column justify-content-center text-center font-weight-bold background-color-no-data w-100"
      >
        <span>{{ $tc("DETAILS_V2.CAMPAIGN_NOT_READY") }}</span>
        <span>{{ $tc("DETAILS_V2.NO_STATS") }}</span>
      </div>

      <div class="container">
        <div class="row">
          <template v-if="!isLoadingCardAudience">
            <CampaignCardAudience
              v-for="(item, index) in cardsAudience"
              :key="index"
              :title="item.title"
              :label="item.label"
              :icon="item.icon"
              :total="item.data.total"
              :averageDay="item.data.averageDay"
              class="mr-sm-3"
            />
          </template>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column mt-2">
      <v-btn
        :loading="exportData.loading"
        :disabled="exportData.loading"
        elevation="2"
        small
        color="blue-grey"
        class="ma-2 white--text btn-export ms-auto ms-auto text-sm-center"
        @click="exportMetrics()"
      >
        EXPORT
        <v-icon right dark> mdi-cloud-download </v-icon>
      </v-btn>
    </div>

    <b-tabs class="mt-2" no-body>
      <b-tab active>
        <template #title>
          <span>Graph View</span>
        </template>

        <div
          v-if="!isLoadingGraph && !hasDataGraphic"
          class="w-100 d-flex align-items-center text-center graph-height"
        >
          <span class="font-weight-bold w-100">{{
            $tc("DETAILS_V2.NO_STATS")
          }}</span>
        </div>

        <ChartCard
          v-if="!isLoadingGraph && hasDataGraphic"
          :sizes="{
            default: 12,
            sm: 12,
            lg: 12
          }"
          :chart="chartArea"
          :customOptions="chartAreaOptions"
        >
        </ChartCard>
      </b-tab>

      <b-tab>
        <template #title>
          <span>Table View</span>
        </template>

        <div
          v-if="!isLoadingMonitoring && !hasDataGraphic"
          class="w-100 d-flex align-items-center text-center graph-height"
        >
          <span class="font-weight-bold w-100">{{
            $tc("DETAILS_V2.NO_STATS")
          }}</span>
        </div>

        <CampaignCardTableMonitoring
          :fields="getHeaderMonitoring"
          :items="itemsMonitoring"
          :isLoading="isLoadingMonitoring"
        />
      </b-tab>
    </b-tabs>

    <div class="d-flex flex-column mt-8">
      <h5 class="card-title mb-0 font-weight-bold">
        {{ $tc("DETAILS_V2.GRAPH_TITLE_DOUGHNUT") }}
      </h5>

      <div
        v-if="isLoadingDoughnut"
        class="w-100 d-flex justify-content-center graph-height align-items-center mt-3"
      >
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        <span class="ml-1 font-weight-bold text-primary">{{
          $tc("DETAILS_V2.LOADING")
        }}</span>
      </div>

      <div class="d-flex flex-column w-100">
        <div
          v-if="!isLoadingDoughnut && !hasDataGraphicDoughnut"
          class="w-100 d-flex align-items-center text-center graph-height mt-3"
        >
          <span class="font-weight-bold w-100">{{
            $tc("DETAILS_V2.NO_STATS")
          }}</span>
        </div>

        <ChartCard
          v-if="!isLoadingDoughnut && hasDataGraphicDoughnut"
          :sizes="{
            default: 12,
            sm: 12,
            lg: 12
          }"
          :chart="chartDoughnut"
          :customOptions="chartDoughnutOptions"
        >
        </ChartCard>
      </div>
    </div>

    <div class="mt-8">
      <h5 class="card-title mb-0 font-weight-bold">
        {{ $tc("DETAILS_V2.ORDER_LINES_TABLE_TITLE") }}
      </h5>

      <div class="mt-3">
        <CampaignCardTable
          :fields="getHeader"
          :items="campaignOrderlinesStats"
          :isLoading="isLoadingStatsCampaignOrderlines"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CampaignCardAudience from "@/components/sponsoring/CampaignCardAudience";
import ChartCard from "@/views/partials/content/cards/Chart";
import CampaignCardTable from "@/components/sponsoring/CampaignCardTable";
import CampaignCardTableMonitoring from "@/components/sponsoring/CampaignCardTableMonitoring";

import {
  headerCampaignDetails,
  headerCampaignMonitoring
} from "@/components/sponsoring/headerConfig";
import {
  chartArea,
  chartAreaOptions,
  chartDoughnut,
  chartDoughnutOptions
} from "@/components/sponsoring/sponsoring.config";

import { renderSeconds } from "@/common/functions";

import {
  getStatsImpressions,
  getStatsCompletionRate,
  getPerformances,
  getStatsExport
} from "@/api/stats/campaigns/getters";
import { getCampaignsV2Stats } from "@/api/campaigns/manage/getters";
import { getItemsLineStats as getStatsCampaignOrderlines } from "@/api/campaigns/lineItems/getters";

const dateExportFormat = () => {
  const now = new Date();
  return (
    now.getFullYear() +
    "-" +
    String(now.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(now.getDate()).padStart(2, "0") +
    "_" +
    String(now.getHours()).padStart(2, "0") +
    "-" +
    String(now.getMinutes()).padStart(2, "0") +
    "-" +
    String(now.getSeconds()).padStart(2, "0")
  );
};

const generateExportFilename = orderId => {
  const formatedDate = dateExportFormat();
  return `campaign_export_${orderId}_${formatedDate}.csv`;
};

const cardsAudience = [
  {
    title: "Listens",
    label: "Av / day",
    icon: "flaticon2-speaker",
    data: {}
  },
  {
    title: "Impressions",
    label: "Av / day",
    icon: "flaticon-eye",
    data: {}
  },
  {
    title: "Time spent",
    label: "Av listening time",
    icon: "flaticon-stopwatch",
    data: {}
  }
];

const defaultStatsCampaignOrderlines = {
  "paid:impressions": 0,
  "paid:plays": 0,
  "paid:budgetsspent": 0,
  "paid:budgetsleft": 0,
  "paid:timelistenings": 0,
  "paid:timelistenings:avg": 0,
  "spent:rate": 0,
  "paid:budget": 0,
  "play:rate": 0,
  "conversion:rate": 0
};

export default {
  name: "CampaignPerformance",

  components: {
    CampaignCardAudience,
    ChartCard,
    CampaignCardTable,
    CampaignCardTableMonitoring
  },

  props: {
    campaignOrder: {
      type: Object,
      required: true
    },
    campaignOrderlines: {
      type: Array,
      required: true
    },
    campaignFilters: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      cardsAudience: cardsAudience,
      isLoadingGraph: true,
      chartArea,
      chartAreaOptions,
      isLoadingDoughnut: true,
      chartDoughnut,
      chartDoughnutOptions,
      isLoadingMonitoring: true,
      itemsMonitoring: [],
      isLoadingCardAudience: true,
      isLoadingStatsCampaignOrderlines: true,
      campaignOrderlinesStats: [],
      exportData: {
        loading: false
      },
      budgetSpent: 0
    };
  },

  computed: {
    getHeader() {
      return headerCampaignDetails;
    },

    getHeaderMonitoring() {
      return headerCampaignMonitoring;
    },

    hasDataGraphic() {
      const firstData = this.chartArea.data.datasets[0].data;
      const secondData = this.chartArea.data.datasets[1].data;

      return firstData.length > 0 || secondData.length > 0;
    },

    hasDataGraphicDoughnut() {
      return this.chartDoughnut.data.datasets[0].data.length > 0;
    }
  },

  beforeDestroy() {
    //Reset the charts data
    this.chartArea.data.datasets[0].data = [];
    this.chartArea.data.datasets[1].data = [];
    this.chartDoughnut.data.datasets[0].data = [];
    this.chartDoughnut.data.labels = [];
  },

  methods: {
    async getStatsGraph() {
      this.isLoadingGraph = true;

      this.chartArea.data.datasets[0].data = [];
      this.chartArea.data.datasets[1].data = [];

      try {
        const response = await getStatsImpressions(
          this.campaignOrder.id,
          this.campaignFilters,
          this.axios
        );

        if (Object.keys(response).length > 0) {
          const graphData = Object.entries(response);

          let graphCategories = [];
          let seriesImpressions = [];
          let seriesListens = [];

          graphData.forEach(element => {
            graphCategories.push(element[0]);
            seriesImpressions.push(element[1].impression);
            seriesListens.push(element[1].play);
          });

          this.chartArea.data.labels = graphCategories;
          this.chartArea.data.datasets[0].data = seriesImpressions;
          this.chartArea.data.datasets[1].data = seriesListens;
        }
      } catch (error) {
        console.error("---ERROR-STATS-GRAPH---");
        console.error(error);
      }
      this.isLoadingGraph = false;
    },

    async getStatsCompletionRate() {
      this.isLoadingDoughnut = true;

      this.chartDoughnut.data.datasets[0].data = [];
      this.chartDoughnut.data.labels = [];

      try {
        const response = await getStatsCompletionRate(
          this.campaignOrder.id,
          this.campaignFilters,
          this.axios
        );

        if (response.data) {
          const labelsGraph = Object.keys(response.data);

          labelsGraph.forEach(element => {
            this.chartDoughnut.data.datasets[0].data.push(
              response.data[element]
            );
          });

          this.chartDoughnut.data.labels = labelsGraph;
        }
      } catch (error) {
        console.error("---ERROR-COMPLETION-RATE---");
        console.error(error);
      }

      this.isLoadingDoughnut = false;
    },

    async getStatsItemsMonitoring() {
      this.isLoadingMonitoring = true;

      try {
        const filters = {
          ...this.campaignFilters,
          colid: this.campaignFilters?.colid || []
        };

        this.itemsMonitoring = await getPerformances(
          this.campaignOrder.id,
          filters,
          this.axios
        );
      } catch (error) {
        console.error("---ERROR-MONITORING---");
        console.error(error);
      }

      this.isLoadingMonitoring = false;
    },

    async getStatsCardAudience() {
      this.isLoadingCardAudience = true;

      this.affectStatsCardAudience(0, 0, 0, 0, 0, 0);

      try {
        const filters = {
          campaigns: [this.campaignOrder.id],
          colid: this.campaignFilters?.colid || [],
          start_date: this.campaignFilters.start_date,
          end_date: this.campaignFilters.end_date
        };

        const response = await getCampaignsV2Stats(filters, this.axios);

        if (Object.keys(response).length > 0) {
          const campaign = response[this.campaignOrder.id];
          const fieldsToCheck = [
            "paid:plays",
            "paid:impressions",
            "paid:timelistenings"
          ];
          const valueFields = [];

          fieldsToCheck.forEach(element => {
            valueFields.push(campaign[element]);
          });

          const sumValueFields = valueFields.reduce((a, b) => a + b);

          if (campaign && sumValueFields > 0) {
            if (this.budgetSpent === 0) {
              this.budgetSpent = campaign["paid:budgetsspent"];
              this.$emit("budgetsSpentChange", {
                budgetsSpent: campaign["paid:budgetsspent"]
              });
            }

            this.affectStatsCardAudience(
              campaign["paid:plays"],
              campaign["play:avg"],
              campaign["paid:impressions"],
              campaign["impressions:avg"],
              campaign["paid:timelistenings"],
              campaign["paid:timelistenings:avg"]
            );
          }
        }
      } catch (error) {
        console.error("---ERROR-MONITORING---");
        console.error(error);
      }

      this.isLoadingCardAudience = false;
    },

    affectStatsCardAudience(
      plays,
      avgPlays,
      impressions,
      avgImpressions,
      timelistenings,
      avgTimelistenings
    ) {
      this.cardsAudience[0].data = {
        total: plays,
        averageDay: avgPlays
      };

      this.cardsAudience[1].data = {
        total: impressions,
        averageDay: avgImpressions
      };

      this.cardsAudience[2].data = {
        total: renderSeconds(timelistenings),
        averageDay: renderSeconds(avgTimelistenings)
      };
    },

    async getStatsCampaignOrderlines() {
      this.isLoadingStatsCampaignOrderlines = true;

      try {
        const colid = this.campaignFilters?.colid || [];
        const filters = {
          ...this.campaignFilters,
          colid
        };

        const statsCampaignOrderlines = await getStatsCampaignOrderlines(
          this.campaignOrder.id,
          filters,
          this.axios
        );

        let campaignOrderlines = this.campaignOrderlines;

        if (colid.length > 0) {
          campaignOrderlines = this.campaignOrderlines.filter(item =>
            colid.includes(item.id)
          );
        }

        this.campaignOrderlinesStats = campaignOrderlines.map(item => {
          const stats =
            item.id in statsCampaignOrderlines
              ? statsCampaignOrderlines[item.id]
              : defaultStatsCampaignOrderlines;

          return { ...item, stats: stats };
        });
      } catch (error) {
        console.error("---ERROR-ORDERLINES-STATS---");
        console.error(error);
      }

      this.isLoadingStatsCampaignOrderlines = false;
    },

    exportMetrics() {
      this.exportData.loading = true;

      getStatsExport(this.campaignOrder.id, this.axios)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            generateExportFilename(this.campaignOrder.id)
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(e => {
          console.error(e);

          this.$bvToast.toast(`Export failed!`, {
            title: `Export`,
            solid: true,
            variant: "danger",
            appendToast: false
          });
        })
        .finally(() => {
          this.exportData.loading = false;
        });
    }
  },

  watch: {
    campaignFilters() {
      this.getStatsGraph();
      this.getStatsCompletionRate();
      this.getStatsItemsMonitoring();
      this.getStatsCardAudience();
      this.getStatsCampaignOrderlines();
    }
  }
};
</script>

<style scoped>
.background-color-no-data {
  height: 112px !important;
  background: #f7f8fa;
}

.graph-height {
  height: 320px;
  background: #f7f8fa;
}

.btn-export {
  background: linear-gradient(45deg, #ff4867 0%, #ff961d 52%, #fff01f 100%);
}
</style>
