<template>
  <div>
    <div>
      <h5 class="card-title mb-0 font-weight-bold">
        {{ $tc("DETAILS_V2.CAMPAIGN_INFORMATIONS_TITLE") }}
      </h5>

      <b-input-group prepend="Campaign ID" class="mt-3">
        <b-form-input v-model="campaignOrder.id" disabled></b-form-input>
        <b-input-group-append>
          <b-button
            variant="outline-success"
            @click="copyToClipboard(campaignOrder.id)"
            >Copy ID</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </div>

    <div class="mt-5">
      <h5 class="card-title mb-0 font-weight-bold">
        {{ $tc("DETAILS_V2.ITEMS_LINES_TITLE") }}
      </h5>

      <div
        v-for="(item, index) in campaignOrderlines"
        :key="`item-line-${index}`"
      >
        <b-input-group
          :prepend="
            `${item.episode.title.substring(0, 50)}${
              item.episode.title.length > 50 ? '...' : ''
            }`
          "
          class="mt-3"
        >
          <b-form-input v-model="item.id" disabled></b-form-input>
          <b-input-group-append>
            <b-button
              variant="outline-success"
              @click="copyToClipboard(item.id, `items-line-${item.id}`)"
              >Copy ID</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>

    <input
      type="hidden"
      id="copyClipboard"
      class="form-control"
      style="width: 100px"
    />

    <div class="mt-8">
      <h5 class="card-title mb-0 font-weight-bold">
        {{ $tc("DETAILS_V2.CHANGE_LOGS_TITLE") }}
      </h5>

      <div class="mt-3">
        <Datatable
          :items="itemLogs"
          :pagination="pagination"
          @changePage="campaignChangeLogs"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Datatable from "@/components/sponsoring/LogTable";
import { getLogsV2 } from "@/api/campaigns/manage/getters";

const CAMPAIGN_LOGS_LIMIT = 50;

export default {
  name: "CampaignOthers",

  components: {
    Datatable
  },

  props: {
    campaignOrder: {
      type: Object,
      required: true
    },
    campaignOrderlines: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      isLoading: false,
      itemLogs: [],
      pagination: {
        total_items: 0,
        items_per_page: 20,
        current_page: 1
      }
    };
  },

  methods: {
    refresh() {
      this.campaignChangeLogs(1);
    },

    campaignChangeLogs(page) {
      this.itemLogs = [];
      this.isLoading = true;

      const filters = {
        resourceId: [
          this.campaignOrder.id,
          ...this.campaignOrderlines.map(item => item.id)
        ],
        limit: CAMPAIGN_LOGS_LIMIT,
        page: page
      };

      getLogsV2(this.axios, this.campaignOrder.id, filters)
        .then(response => {
          this.itemLogs = response.data || [];

          this.pagination = {
            total_items: response.total_items,
            items_per_page: response.items_per_page,
            current_page: response.current_page
          };
        })
        .catch(error => {
          console.error("---ERROR-CAMPAIGN-CHANGE-LOGS---");
          console.error(error);

          this.$bvToast.toast(`Unable to retrieve campaign logs.`, {
            title: `Campaign change logs`,
            solid: true,
            variant: "danger",
            appendToast: false
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    copyToClipboard(value) {
      let copyContent = document.querySelector(`#copyClipboard`);
      copyContent.value = value;
      copyContent.setAttribute("type", "text");
      copyContent.focus();

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        copyContent.contentEditable = true;
        copyContent.readOnly = true;

        const range = document.createRange();
        range.selectNodeContents(copyContent);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        copyContent.setSelectionRange(0, 999999);
      } else {
        copyContent.select();
      }

      let message = "success";
      let variant = "success";
      let autoHideDelay = 3000;

      try {
        document.execCommand("Copy");
      } catch (err) {
        message = "unknown error";
        variant = "danger";
      }

      this.$bvToast.toast(`Copy to clipboard`, {
        title: `Copy to clipboard ${message}`,
        toaster: "b-toaster-bottom-right",
        solid: true,
        variant: variant,
        appendToast: false,
        autoHideDelay: autoHideDelay
      });

      copyContent.setAttribute("type", "hidden");
    }
  }
};
</script>
