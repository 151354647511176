<template>
  <div>
    <div class="d-flex flex-column align-items-center">
      <div class="card card-custom mt-5 mt-sm-0 w-75">
        <CampaignHeader
          :campaign-order="campaignOrder"
          :categories="categories"
          :languages="languages"
          :budgetSpent="budgetSpent"
        />
      </div>

      <!-- Filter -->
      <div class="d-flex w-75 my-5">
        <InputDate
          period=""
          class="ml-3 mr-3"
          :right="false"
          :reset="reset"
          :maxDate="this.campaignOrder.maxDate"
          :minDate="this.campaignOrder.minDate"
          @datepickerChange="datepickerChange"
        />
        <Select
          class="float-left mr-3"
          label="Select episode(s)"
          labelField="name"
          ref="selectedCampaignOrderlines"
          :options="campaignOrderlineToSelect"
          :items="selectedCampaignOrderlines"
        ></Select>
        <v-btn
          elevation="2"
          small
          color="blue-grey"
          class="mt-1 white--text voxeus-gradient text-sm-center"
          @click="applyFilter"
        >
          Apply
          <v-icon right dark>mdi-filter-outline</v-icon>
        </v-btn>
        &nbsp;
        <v-btn
          v-if="'colid' in filters"
          elevation="2"
          small
          color="blue-grey"
          class="mt-1 white--text voxeus-gradient text-sm-center"
          @click="resetFilter"
        >
          Reset filter
          <v-icon right dark>mdi-filter-remove-outline</v-icon>
        </v-btn>
      </div>

      <div class="d-flex flex-column w-75">
        <div class="container-tabs">
          <div
            v-for="(item, index) in tabs"
            :key="`tabs_${index}`"
            class="mr-3 bb-tab shadow-sm"
            :class="{ 'bb-tab-active': item.active }"
            @click="changeTabs(index)"
          >
            <span>{{ item.text }}</span>
          </div>
        </div>

        <div class="container-card">
          <div v-show="getCurrentSetp === 0">
            <CampaignPerformance
              ref="CampaignPerformance"
              :campaign-order="campaignOrder"
              :campaign-orderlines="campaignOrderlines"
              :campaign-filters="filters"
              @budgetsSpentChange="budgetsSpentChange"
            />
          </div>

          <div v-show="getCurrentSetp === 1">
            <CampaignAudience
              ref="CampaignAudience"
              :campaign-order="campaignOrder"
              :campaign-filters="filters"
              :is-show="getCurrentSetp === 1"
            />
          </div>

          <div v-show="getCurrentSetp === 2">
            <CampaignOthers
              ref="CampaignOthers"
              :campaign-order="campaignOrder"
              :campaign-orderlines="campaignOrderlines"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignHeader from "@/components/sponsoring/CampaignHeader";
import CampaignAudience from "@/components/sponsoring/CampaignAudience";
import CampaignPerformance from "@/components/sponsoring/CampaignPerformance";
import CampaignOthers from "@/components/sponsoring/CampaignOthers";
import Select from "@/components/Select";
import InputDate from "@/components/subheaders/filters/inputs/InputDate.vue";

import { mapGetters } from "vuex";
import { getCampaignV2 } from "@/api/campaigns/manage/getters";
import { getItemsLineFromSponsoring } from "@/api/campaigns/lineItems/getters";
import { getDayTimestampUTC } from "@/common/functions";

export default {
  name: "DetailsV2",

  components: {
    CampaignHeader,
    CampaignAudience,
    CampaignPerformance,
    CampaignOthers,
    Select,
    InputDate
  },

  data() {
    return {
      filters: {},
      datePickerPeriod: [],
      reset: false,
      categories: [],
      languages: [],
      budgetSpent: 0,
      campaignOrder: {
        program: {
          name: ""
        },
        tags: []
      },
      tabs: [
        {
          text: "Performance",
          active: true
        },
        {
          text: "Audience",
          active: false
        }
      ],
      campaignOrderlines: [],
      selectedCampaignOrderlines: []
    };
  },

  computed: {
    ...mapGetters({ isAdmin: "isAdmin" }),

    getIabTagsSelected() {
      let tags = [];

      if (this.campaignOrder.tags.length > 0) {
        tags = this.campaignOrder.tags.filter(
          element => element.tagType.name === "iab_type"
        );
      }

      return tags;
    },

    getLangTagsSelected() {
      let tags = [];

      if (this.campaignOrder.tags.length > 0) {
        tags = this.campaignOrder.tags.filter(
          element => element.tagType.name === "lang_type"
        );
      }

      return tags;
    },

    getCompany() {
      return this.campaignOrder.company;
    },

    getCurrentSetp() {
      return this.tabs.findIndex(element => element.active);
    },

    campaignOrderlineToSelect() {
      return this.campaignOrderlines.map(element => ({
        id: element.id,
        name: element.episode.title
      }));
    },

    campaignDate() {
      const todayDate = new Date();
      let endDateFilter = new Date(this.campaignOrder.maxDate.split(" ")[0]);

      if (endDateFilter.getTime() > todayDate.getTime()) {
        endDateFilter = todayDate;
      }

      return {
        start_date: getDayTimestampUTC(
          this.campaignOrder.minDate.split(" ")[0]
        ),
        end_date: getDayTimestampUTC(endDateFilter, true)
      };
    }
  },

  async beforeMount() {
    if (this.isAdmin) {
      this.tabs.push({
        text: "Others",
        active: false
      });
    }

    const campaignId = this.$route.params.id;

    await Promise.all([
      this.campaignOrderInfos(campaignId),
      this.campaingOrderlinesInfos(campaignId)
    ]);

    this.resetFilter();

    this.$refs.CampaignOthers.refresh();
  },

  methods: {
    campaignOrderInfos(campaignId) {
      this.campaignOrder = {};
      this.isCampaignOrderInfosLoading = true;

      return getCampaignV2(campaignId, this.axios)
        .then(response => {
          this.campaignOrder = response;

          this.categories = this.getIabTagsSelected;
          this.languages = this.getLangTagsSelected;
        })
        .catch(error => {
          console.error("---ERROR-CAMPAIGN-ORDER---");
          console.error(error);

          this.$bvToast.toast(`Unable to retrieve campaign infos.`, {
            title: `Campaign infos`,
            solid: true,
            variant: "danger",
            appendToast: false
          });
        })
        .finally(() => {
          this.isCampaignOrderInfosLoading = false;
        });
    },

    campaingOrderlinesInfos(campaignId) {
      this.campaignOrderlines = [];
      this.isCampaignOrderlinesInfosLoading = true;

      return getItemsLineFromSponsoring(campaignId, {}, this.axios)
        .then(response => {
          this.campaignOrderlines = response || [];
        })
        .catch(error => {
          console.error("---ERROR-CAMPAIGN-ORDERLINES---");
          console.error(error);

          this.$bvToast.toast(`Unable to retrieve campaign orderlines infos.`, {
            title: `Campaign orderlines infos`,
            solid: true,
            variant: "danger",
            appendToast: false
          });
        })
        .finally(() => {
          this.isCampaignOrderlinesInfosLoading = false;
        });
    },

    applyFilter() {
      this.reset = false;
      const selectedCampaignOrderlines = this.$refs.selectedCampaignOrderlines.selectedItems.map(
        item => item.id
      );

      this.filters = {
        ...this.campaignDate,
        colid: selectedCampaignOrderlines
      };

      if (this.datePickerPeriod.length > 0) {
        const [start_date, end_date] = this.datePickerPeriod;
        this.filters = {
          ...this.filters,
          start_date,
          end_date
        };
      }
    },

    resetFilter() {
      this.reset = true;
      this.selectedCampaignOrderlines = [];
      this.filters = {
        ...this.campaignDate
      };
    },

    changeTabs(index) {
      this.tabs[this.getCurrentSetp].active = false;
      this.tabs[index].active = true;
    },

    budgetsSpentChange(value) {
      const budgetsSpent = value?.budgetsSpent || 0;
      this.budgetSpent = budgetsSpent;
    },

    datepickerChange(value) {
      const dateRange = value?.dateRange || [];

      if (dateRange.length === 0 || dateRange.length > 2) {
        this.datePickerPeriod = [];
        return;
      }

      const [start, end] = dateRange;

      this.datePickerPeriod = [
        getDayTimestampUTC(start),
        getDayTimestampUTC(end, true)
      ];
    }
  }
};
</script>

<style scoped>
.card-custom {
  padding: 2rem 2.25rem;
}

.bb-tab {
  background: #ffffff;
  color: #ff961d;
  font-weight: bold;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.bb-tab-active {
  background: #ff961d;
  color: #ffffff;
}

.bb-tab:hover {
  background: #ff961d;
  color: #ffffff;
  cursor: pointer;
}

.container-card {
  background: #ffffff;
  border-radius: 0px 10px 10px 10px;
  box-shadow: 0px 0px 30px 0px #464e5f14;
  padding: 2rem 2.25rem;
  min-height: 340px;
}

.container-spinner {
  height: 150px;
}

.container-tabs {
  display: flex;
  flex-direction: row;
}

@media (max-width: 380px) {
  .container-tabs {
    /* overflow-x create a padding on desktop */
    overflow-x: scroll;
  }
}

.voxeus-gradient {
  background: linear-gradient(45deg, #ff4867 0%, #ff961d 52%, #fff01f 100%);
}
</style>
