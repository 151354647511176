export const headerConfigEpsiode = [
  {
    label: "Title Episode",
    key: "title",
    sortable: false
  },
  {
    label: "Start",
    key: "startAt",
    sortable: false
  },
  {
    label: "End",
    key: "endAt",
    sortable: false
  },
  {
    label: "Budget",
    key: "budget",
    sortable: false
  },
  {
    label: "Priority",
    key: "priority",
    sortable: false
  },
  {
    label: "ABR",
    key: "artificialBudgetRatio",
    sortable: false
  },
  {
    label: "Actions",
    key: "actions",
    sortable: false
  }
];

export const headerConfigEpsioderAdmin = {
  label: "CPL",
  key: "cpl",
  sortable: false
};

export const headerCampaignDetails = [
  { key: "episode", label: "Episode" },
  { key: "stats.paid:plays", label: "Vol." },
  { key: "playRate", label: "% of total" },
  { key: "stats.paid:impressions", label: "Vol." },
  { key: "conversionRate", label: "% Transformation" },
  { key: "averageTimeListenings", label: "Average time" },
  { key: "totalTimeListening", label: "Total time" }
];

export const headerCampaignMonitoring = [
  { key: "date", label: "Date" },
  { key: "impression", label: "Impressions" },
  { key: "click", label: "Clicks" },
  { key: "play", label: "Plays" }
];

export const headerDomainsStats = [
  {
    label: "Domains",
    key: "domain"
  },
  {
    label: "Listens",
    key: "play",
    sortable: true
  },
  {
    label: "Impressions",
    key: "impression",
    sortable: true
  },
  {
    label: "% Transformation",
    key: "transformation:rate",
    sortable: true,
    formatter: (value, key, item) => {
      return `${item["transformation:rate"]} %`;
    }
  }
];

export const headerGeoloc = [
  {
    label: "Country name",
    key: "country_name"
  },
  {
    label: "Listens",
    key: "listen"
  }
];

export const optionsWorldMap = {
  tooltip: {
    trigger: "item",
    showDelay: 0,
    transitionDuration: 0.2
  },
  borderColor: "#000",
  visualMap: {
    show: false,
    inRange: {
      color: [
        "#313695",
        "#4575b4",
        "#74add1",
        "#abd9e9",
        "#e0f3f8",
        "#ffffbf",
        "#fee090",
        "#fdae61",
        "#f46d43",
        "#d73027",
        "#a50026"
      ]
    }
  },
  toolbox: {
    show: false
  },
  series: [
    {
      name: "Number of listens",
      type: "map",
      roam: "move",
      map: "world",
      top: 200,
      zoom: 3,
      zoomMin: 0,
      itemStyle: {
        borderColor: "#EFEFEF",
        areaColor: "#D5D5D5"
      },
      emphasis: {
        label: {
          show: false
        },
        itemStyle: {
          areaColor: "#00e9ff"
        }
      },
      data: []
    }
  ]
};
