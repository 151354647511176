<template>
  <div>
    <b-table
      :items="lists"
      :fields="fields"
      responsive
      striped
      hover
      :busy="isLoading"
      show-empty
      per-page="10"
    >
      <template #custom-foot>
        <b-tr>
          <b-td class="text-center"><b>Total</b></b-td>
          <b-td
            ><b>{{ (totals && totals["total_impression"]) ?? 0 }}</b></b-td
          >
          <b-td
            ><b>{{ (totals && totals["total_click"]) ?? 0 }}</b></b-td
          >
          <b-td
            ><b>{{ (totals && totals["total_play"]) ?? 0 }}</b></b-td
          >
        </b-tr>
      </template>

      <template #empty>
        <div class="d-flex justify-content-center">
          <span class="font-weight-bold">{{ $tc("DETAILS_V2.NO_STATS") }}</span>
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(date)="data">
        {{ data.item.date }}
      </template>

      <template #cell(impression)="data">
        {{ data.item.impression }}
      </template>

      <template #cell(click)="data">
        {{ data.item.click }}
      </template>

      <template #cell(play)="data">
        {{ data.item.play }}
      </template>
    </b-table>

    <div class="d-flex justify-content-center">
      <b-pagination
        v-model="currentPage"
        :total-rows="items.length"
        :per-page="perPage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { renderSeconds } from "@/common/functions";

export default {
  props: {
    items: {
      type: Array,
      required: false
    },
    fields: {
      type: Array,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      currentPage: 1,
      perPage: 10
    };
  },

  methods: {
    truncateTitle(title) {
      const MAX_CHARACTERS = 30;

      if (title.length > MAX_CHARACTERS) {
        title = `${title.substring(0, MAX_CHARACTERS)}...`;
      }

      return title;
    },

    renderSeconds(time) {
      return renderSeconds(time);
    }
  },

  computed: {
    totals() {
      return this.items[this.items.length - 1];
    },
    lists() {
      return this.items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    }
  }
};
</script>
