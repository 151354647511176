import { render, staticRenderFns } from "./CampaignAudience.vue?vue&type=template&id=7e10871c&scoped=true&"
import script from "./CampaignAudience.vue?vue&type=script&lang=js&"
export * from "./CampaignAudience.vue?vue&type=script&lang=js&"
import style0 from "./CampaignAudience.vue?vue&type=style&index=0&id=7e10871c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e10871c",
  null
  
)

export default component.exports