<template>
  <div class="overflow-hidden">
    <b-table
      :items="items"
      :fields="headers"
      :current-page="pagination.currentPage"
      :per-page="pagination.items_per_page"
      responsive
      striped
      hover
      bordered
      outlined
      show-empty
    >
      <template #empty>
        <div class="d-flex justify-content-center">
          <span class="font-weight-bold">{{ $tc("DETAILS_V2.NO_STATS") }}</span>
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(user)="data">
        {{ data.item.user.email }}
      </template>

      <template #cell(fieldsChanged)="data">
        <span
          v-for="(value, field, index) in data.item.fieldsChanged"
          :key="`field_${index}`"
        >
          <span>
            <b>{{ field }}: </b
            ><span v-if="value['initial_value'] !== ''"
              >{{ value["initial_value"] }} &#8594; </span
            >{{ value["current_value"] }}<br />
          </span>
        </span>
      </template>
    </b-table>
    <div class="w-100 d-flex">
      <b-pagination
        v-bind:style="{ margin: '0 auto' }"
        align="center"
        justify="center"
        v-model="pagination.current_page"
        :total-rows="pagination.total_items"
        :per-page="pagination.items_per_page"
        @change="changePage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { headers } from "@/common/config/logs/menu.config";

export default {
  data() {
    return {
      fields: headers,
      headers: headers
    };
  },

  props: {
    items: {
      type: Array,
      required: false
    },
    pagination: {
      type: Object,
      required: false
    }
  },

  methods: {
    changePage(page) {
      this.$parent.$emit("changePage", page);
    }
  }
};
</script>
