var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-row flex-wrap justify-content-sm-between justify-content-center"},[(_vm.isLoadingCardAudience)?_c('div',{staticClass:"d-flex flex-column justify-content-center text-center font-weight-bold background-color-no-data w-100"},[_c('span',[_vm._v(_vm._s(_vm.$tc("DETAILS_V2.CAMPAIGN_NOT_READY")))]),_c('span',[_vm._v(_vm._s(_vm.$tc("DETAILS_V2.NO_STATS")))])]):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(!_vm.isLoadingCardAudience)?_vm._l((_vm.cardsAudience),function(item,index){return _c('CampaignCardAudience',{key:index,staticClass:"mr-sm-3",attrs:{"title":item.title,"label":item.label,"icon":item.icon,"total":item.data.total,"averageDay":item.data.averageDay}})}):_vm._e()],2)])]),_c('div',{staticClass:"d-flex flex-column mt-2"},[_c('v-btn',{staticClass:"ma-2 white--text btn-export ms-auto ms-auto text-sm-center",attrs:{"loading":_vm.exportData.loading,"disabled":_vm.exportData.loading,"elevation":"2","small":"","color":"blue-grey"},on:{"click":function($event){return _vm.exportMetrics()}}},[_vm._v(" EXPORT "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-cloud-download ")])],1)],1),_c('b-tabs',{staticClass:"mt-2",attrs:{"no-body":""}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Graph View")])]},proxy:true}])},[(!_vm.isLoadingGraph && !_vm.hasDataGraphic)?_c('div',{staticClass:"w-100 d-flex align-items-center text-center graph-height"},[_c('span',{staticClass:"font-weight-bold w-100"},[_vm._v(_vm._s(_vm.$tc("DETAILS_V2.NO_STATS")))])]):_vm._e(),(!_vm.isLoadingGraph && _vm.hasDataGraphic)?_c('ChartCard',{attrs:{"sizes":{
          default: 12,
          sm: 12,
          lg: 12
        },"chart":_vm.chartArea,"customOptions":_vm.chartAreaOptions}}):_vm._e()],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Table View")])]},proxy:true}])},[(!_vm.isLoadingMonitoring && !_vm.hasDataGraphic)?_c('div',{staticClass:"w-100 d-flex align-items-center text-center graph-height"},[_c('span',{staticClass:"font-weight-bold w-100"},[_vm._v(_vm._s(_vm.$tc("DETAILS_V2.NO_STATS")))])]):_vm._e(),_c('CampaignCardTableMonitoring',{attrs:{"fields":_vm.getHeaderMonitoring,"items":_vm.itemsMonitoring,"isLoading":_vm.isLoadingMonitoring}})],1)],1),_c('div',{staticClass:"d-flex flex-column mt-8"},[_c('h5',{staticClass:"card-title mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$tc("DETAILS_V2.GRAPH_TITLE_DOUGHNUT"))+" ")]),(_vm.isLoadingDoughnut)?_c('div',{staticClass:"w-100 d-flex justify-content-center graph-height align-items-center mt-3"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning"}}),_c('span',{staticClass:"ml-1 font-weight-bold text-primary"},[_vm._v(_vm._s(_vm.$tc("DETAILS_V2.LOADING")))])],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column w-100"},[(!_vm.isLoadingDoughnut && !_vm.hasDataGraphicDoughnut)?_c('div',{staticClass:"w-100 d-flex align-items-center text-center graph-height mt-3"},[_c('span',{staticClass:"font-weight-bold w-100"},[_vm._v(_vm._s(_vm.$tc("DETAILS_V2.NO_STATS")))])]):_vm._e(),(!_vm.isLoadingDoughnut && _vm.hasDataGraphicDoughnut)?_c('ChartCard',{attrs:{"sizes":{
          default: 12,
          sm: 12,
          lg: 12
        },"chart":_vm.chartDoughnut,"customOptions":_vm.chartDoughnutOptions}}):_vm._e()],1)]),_c('div',{staticClass:"mt-8"},[_c('h5',{staticClass:"card-title mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$tc("DETAILS_V2.ORDER_LINES_TABLE_TITLE"))+" ")]),_c('div',{staticClass:"mt-3"},[_c('CampaignCardTable',{attrs:{"fields":_vm.getHeader,"items":_vm.campaignOrderlinesStats,"isLoading":_vm.isLoadingStatsCampaignOrderlines}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }